<template>
  <div>
    <div class="pa-3">
      <h1 class="page-title mb-3">
        Список профилей
      </h1>
      <v-btn
        class="text-capitalize"
        color="green"
        style="color: #fefefe"
        elevation="0"
        height="29"
        tile
        @click="isShowProfileDalog = !isShowProfileDalog"
      >
        Добавить
      </v-btn>
    </div>

    <div class="text-left mx-5">
      <profile-dialog
        v-model="isShowProfileDalog"
        v-if="isShowProfileDalog"
        :listProfileType="listProfileType"
        @handleSelectedProfile="addNewProfile"
      ></profile-dialog>
    </div>

    <v-container fluid style="max-width: 99.99%">
      <v-row>
        <v-col cols="4" md="4">
          <div
            class="text-left mx-n2"
            style="
              font-style: normal;
              font-weight: bolder;
              font-size: 12px;
              line-height: 15px;
              height: 28px;
              align-content: center;
              display: grid;
              color: #000000;
              background-color: #f8f8f8;
            "
          >
            <div class="mx-3">Наименование</div>
          </div>
          <v-list dense>
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
              @change="selectChanged"
            >
              <v-list-item
                v-for="(item, idx) in items"
                :key="idx"
                @click="select(item)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="text-left"
                    v-text="
                      `${item.name} (${
                        item.device_type === undefined
                          ? profileTypes[item.profile_type]
                          : profileTypes[item.device_type]
                      })`
                    "
                  ></v-list-item-title>
                  <!--  -->
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-divider
          :style="{ height: windowHeight - 170 + 'px' }"
          class="my-3"
          vertical
        />
        <v-col cols="8" md="8">
          <div
            class="text-left mx-n2"
            style="
              font-style: normal;
              font-weight: bolder;
              font-size: 12px;
              line-height: 15px;
              align-content: center;
              display: grid;
              height: 28px;
              color: #000000;
              background-color: #f8f8f8;
            "
          >
            <div class="mx-3">Свойства</div>
          </div>
          <v-container class="mx-n3" fluid style="max-width: 99.99%">
            <profile-form
              v-if="formData"
              :formData="formData"
              :listProfileType="listProfileType"
              @onSubmit="submitForm"
              @onError="catchError"
            />
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <!-- <InfoDialog
      :info-dialog-text="infoDialogText"
      :is-info-dialog="isInfoDialog"
      @accept="closeWarn"
    /> -->

    <v-snackbar top v-model="isInfoDialog" color="white">
      <span class="black--text">
        {{ infoDialogText }}
      </span>
    </v-snackbar>

    <v-dialog v-model="isConfirmationDialog" width="400px">
      <v-card tile>
        <v-card-title> Внимание </v-card-title>
        <v-card-text>
          {{ confirmationDialogText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="text-capitalize"
            color="#5FB760"
            elevation="0"
            height="29"
            style="font-size: 12px"
            tile
            @click="submitDelete"
          >
            Да
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="#5FB760"
            elevation="0"
            height="29"
            style="font-size: 12px"
            tile
            @click="closeConfirmDialog"
          >
            Отменить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import InfoDialog from "@/components/Dialogs/InfoDialog";
import ProfileDialog from "@/components/Profiles/ProfileDialog";
import ProfileForm from "@/components/Profiles/ProfileForm.vue";

export default {
  name: "Profiles",
  components: { ProfileDialog, ProfileForm },
  data: () => ({
    isShowProfileDalog: false,
    selectedItem: {},
    listProfileType: [
      {
        id: "0",
        value: "Планшет",
        type: "TABLET",
      },
      {
        id: "1",
        value: "Датчик движения",
        type: "SENSOR",
      },
      {
        id: "2",
        value: "Пространство",
        type: "SPACE",
      },
    ],
    profileTypes: {
      SENSOR: "Датчик движения",
      SPACE: "Пространство",
      TABLET: "Планшет",
      space: "Пространство",
    },
    formData: null,
    isInfoDialog: false,
    infoDialogText: "",
    isConfirmationDialog: false,
    confirmationDialogText: "",
    operatorsItems: [],
    tmpData: null,
  }),
  computed: {
    windowHeight() {
      return window.innerHeight;
    },
    items() {
      return this.$store.getters.getProfilesDataArray.concat(
        this.$store.getters.getSpaceProfiles
      );
    },
  },
  methods: {
    select(item) {
      if (item.equipment) item.equipment_ids = item.equipment;
      this.formData = { ...item };

      if (this.formData.equipment_ids) {
        this.formData.equipment_ids = this.formData.equipment_ids.map(
          (item) => item.id
        );
      }
    },
    submitForm(response) {
      let crudType = !this.formData.id ? "create" : "update";

      const fetchAllProfiles = () => {
        this.$store
          .dispatch("fetchProfiles")
          .then(() => {
            this.$store.dispatch("fetchSpaceProfile").then(() => {
              this.infoDialogText =
                crudType === "create"
                  ? "Профиль успешно добавлен"
                  : "Профиль успешно изменен";
              this.isInfoDialog = true;
              this.selectedItem = response;
            });
          })
          .catch((err) => {
            this.isInfoDialog = true;
            this.infoDialogText = `Ошибка: ${err.response.data.code} ${err.response.data.error}`;
          });
      };
      fetchAllProfiles();
    },
    selectChanged() {
      if (this.selectedItem === undefined) this.formData = null;
    },
    clearForm() {
      this.formData = {};
    },
    deleteItem(item) {
      this.formData = { ...item };
      this.tmpData = { ...item };
      this.confirmationDialogText = `Вы действительно хотите удалить ${item.name}?`;
      this.isConfirmationDialog = true;
    },
    closeConfirmDialog() {
      this.isConfirmationDialog = false;
    },
    submitDelete() {
      const action =
        this.tmpData.profile_type === "space"
          ? "deleteSpaceProfile"
          : "deleteProfile";
      this.$store.dispatch(action, this.tmpData.id).then(() => {
        this.isConfirmationDialog = false;
        this.formData = undefined;
        this.infoDialogText = "Профиль успешно удален";
        this.isInfoDialog = true;
        this.$store.dispatch("fetchProfiles");
        this.$store.dispatch("fetchSpaceProfile");
      });
    },
    closeWarn() {
      this.isInfoDialog = false;
    },
    addNewProfile(newProfile) {
      if (!newProfile) return;
      this.clearForm();
      this.formData["device_type"] = newProfile;
      this.isShowProfileDalog = false;
    },
    catchError(err) {
      this.isInfoDialog = true;
      this.infoDialogText = `Ошибка: ${err.response.data.code} ${err.response.data.error}`;
    },
  },
  mounted() {
    this.$store.dispatch("fetchProfiles");
    this.$store.dispatch("fetchSpaceProfile");
  },
};
</script>

<style lang="scss" scoped>
/* @import "../style/OldStyles"; */
</style>
