<template>
  <div>
    <h3 class="text-left font-weight-bold dialog-window__font">
      Тип: {{ title }}
    </h3>

    <!-- name -->
    <error-info :error="'name' in errors"> {{ errors.name }} </error-info>
    <div class="d-flex align-center mb-4">
      <v-row no-gutters class="align-center">
        <v-col cols="2" class="text-left">
          <div class="dialog-window__font">Наименование</div>
        </v-col>
        <v-col>
          <v-text-field
            dense
            hide-details
            outlined
            class="base-text-field"
            v-model="formData.name"
          />
        </v-col>
      </v-row>
    </div>
    <error-info :error="'equipment' in errors">
      {{ errors.equipment }}
    </error-info>
    <div>
      <v-row no-gutters class="align-center">
        <v-col cols="2" class="text-left align-self-start">
          <div class="dialog-window__font">
            Список оборудования
          </div>
        </v-col>
        <v-col>
          <v-select
            dense
            outlined
            hide-details
            multiple
            class="base-select"
            :items="listDevices"
            v-model="formData.equipment_ids"
            item-text="name"
            item-value="id"
          >
            <template v-slot:selection="{ item }">
              <div style="width: 100%">
                {{ item.name }}
              </div>
            </template>
          </v-select></v-col
        >
      </v-row>
    </div>
  </div>
</template>

<script>
import ErrorInfo from "../Dialogs/ErrorInfo.vue";

export default {
  name: "FormEquipment",
  components: { ErrorInfo },
  props: {
    title: {
      type: String,
      default: "Пространство",
    },
    value: {},
    errors: {},
  },
  data() {
    return {
      equipment: null,
    };
  },
  mounted() {
    this.$store.dispatch("fetchListEquipments");
  },
  computed: {
    formData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    listDevices() {
      return this.$store.getters.getListEquipments;
    },
  },
};
</script>
