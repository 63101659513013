<template>
  <div>
    <h3 class="text-left dialog-window__font" style="font-weight: 700">
      Тип: {{ title }}
    </h3>

    <error-info :error="'name' in errors">
      {{ errors.name }}
    </error-info>
    <div class="pa-0">
      <!-- name -->
      <div class="d-flex align-center mb-4">
        <div
          class="d-flex align-center justify-space-between"
          style="width: 380px"
        >
          <div class="dialog-window__font">
            Наименование
          </div>
          <v-text-field
            dense
            hide-details
            outlined
            class="base-text-field"
            v-model="formData.name"
            style="max-width: 241px"
          />
        </div>
      </div>
      <!-- Прошивка -->
      <div class="d-flex align-center mb-4">
        <div
          class="d-flex align-center justify-space-between"
          style="min-width: 380px"
        >
          <div class="dialog-window__font">Прошивка</div>
          <v-text-field
            dense
            hide-details
            outlined
            class="base-text-field"
            v-model="formData.firmware"
            style="max-width: 241px"
          />
        </div>

        <label class="input-img__fake-btn d-flex align-center justify-center">
          <input
            class=""
            type="file"
            ref="imageFile"
            @change="handleFileUpload()"
            style="display: none"
          />
          <span>...</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorInfo from "../Dialogs/ErrorInfo.vue";

export default {
  name: "FormSensor",
  components: { ErrorInfo },
  props: {
    title: {
      type: String,
      default: "Датчик движения",
    },
    value: {
      type: Object,
      require: true,
    },
    errors: {},
  },
  data() {
    return {
      imageFile: null,
    };
  },
  methods: {
    handleFileUpload() {
      this.imageFile = this.$refs.imageFile.files[0];
      this.formData.imageFile = this.imageFile;
    },
  },
  computed: {
    formData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.input-img__fake-btn {
  margin-left: 16px;
  width: 32px;
  height: 24px;
  background-color: #eeac57;
  cursor: pointer;
}
</style>
