var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"max-width":"1232px"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[(
        _vm.formData['device_type'] === '0' ||
          _vm.formData['device_type'] === 'TABLET'
      )?_c('form-tablet',{attrs:{"errors":_vm.errors},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}}):_vm._e(),(
        _vm.formData['device_type'] === '1' ||
          _vm.formData['device_type'] === 'SENSOR'
      )?_c('form-sensor',{attrs:{"errors":_vm.errors},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}}):_vm._e(),(
        _vm.formData['device_type'] === '2' ||
          _vm.formData['device_type'] === 'SPACE' ||
          _vm.formData.profile_type === 'space'
      )?_c('form-equipment',{attrs:{"errors":_vm.errors},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}}):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"white--text",attrs:{"tile":"","color":"green","type":"submit","elevation":"0"}},[_vm._v("Сохранить")]),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }