<template>
  <div>
    <h3 class="text-left dialog-window__font" style="font-weight: 700">
      Тип: {{ title }}
    </h3>

    <error-info :error="'name' in errors">
      {{ errors.name }}
    </error-info>
    <div class="pa-0">
      <!-- name -->
      <div class="d-flex align-center mb-4">
        <div
          class="d-flex align-center justify-space-between "
          style="width: 380px"
        >
          <div class="dialog-window__font">
            Наименование
          </div>
          <v-text-field
            dense
            hide-details
            outlined
            class="base-text-field"
            v-model="formData.name"
            style="max-width: 241px"
          />
        </div>
      </div>
      <div class="d-flex align-center mb-4">
        <div
          class="d-flex align-center justify-space-between"
          style="min-width: 380px"
        >
          <div class="dialog-window__font">Прошивка</div>
          <v-text-field
            dense
            hide-details
            outlined
            class="base-text-field"
            v-model="formData.firmware"
            style="max-width: 241px"
          />
        </div>

        <label class="input-img__fake-btn d-flex align-center justify-center">
          <input
            type="file"
            ref="imageFile"
            @change="() => {}"
            style="display: none"
          />
          <span>...</span>
        </label>
      </div>

      <div class="d-flex align-center mb-4">
        <div
          class="d-flex align-center justify-space-between"
          style="width: 380px"
        >
          <div class="dialog-window__font text-left">
            Отменять бронирование при неактивности
          </div>
          <div style="max-width: 90px">
            <v-text-field
              dense
              hide-details
              outlined
              class="base-text-field"
              v-model="formData.inactivity_cancel_timeout"
            ></v-text-field>
          </div>
        </div>

        <span class="ml-2 dialog-window__font">мин</span>
      </div>

      <div class="d-flex align-center mb-4">
        <div
          class="d-flex align-center justify-space-between"
          style="width: 380px"
        >
          <div class="dialog-window__font text-left">
            Максимальное время встречи
          </div>
          <div style="max-width: 90px">
            <v-text-field
              dense
              hide-details
              outlined
              class="base-text-field"
              v-model="formData.max_reservation_time"
            />
          </div>
        </div>

        <span class="ml-2 dialog-window__font">мин</span>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorInfo from "../Dialogs/ErrorInfo.vue";
export default {
  components: { ErrorInfo },
  name: "FormTablet",
  props: {
    title: {
      type: String,
      default: "Планшет",
    },
    value: {
      type: Object,
      require: true,
    },
    errors: {},
  },
  data() {
    return {};
  },
  computed: {
    formData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.input-img__fake-btn {
  margin-left: 16px;
  width: 32px;
  height: 24px;
  background-color: #eeac57;
  cursor: pointer;
}
</style>
