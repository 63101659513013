<template>
  <v-dialog v-model="isDisplay" max-width="680">
    <v-card>
      <v-container>
        <v-card-title class="pa-0" style="font-size: 12px; font-weight: 700">
          Выберите тип профиля
        </v-card-title>

        <div class="mb-4">
          <v-select
            dense
            hide-details
            flat
            required
            outlined
            class="base-select"
            placeholder="Профиль"
            :items="listProfileType"
            item-text="value"
            item-value="id"
            v-model="selectedProfile"
          />
        </div>

        <v-card-actions class="d-flex pa-0">
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            elevation="0"
            @click="$emit('handleSelectedProfile', selectedProfile)"
            style="border-radius: 0; font-size: 12px; background: #5fb760"
            >Ок</v-btn
          >
          <v-btn
            @click="isDisplay = !isDisplay"
            style="border-radius: 0; font-size: 12px"
            elevation="0"
            >Отмена</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProfileDialog",
  props: {
    value: Boolean,
    listProfileType: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      selectedProfile: null,
    };
  },
  computed: {
    isDisplay: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
/* .v-select >>> .v-input__slot {
  border: 1px solid #e9e9e9;
  margin: 0;
} */
</style>
