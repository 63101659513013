<template>
  <!-- <div style="max-width: 504px"> -->
  <div style="max-width: 1232px">
    <v-form class="" @submit.prevent="onSubmit()">
      <form-tablet
        :errors="errors"
        v-if="
          formData['device_type'] === '0' ||
            formData['device_type'] === 'TABLET'
        "
        v-model="formData"
      />

      <form-sensor
        :errors="errors"
        v-if="
          formData['device_type'] === '1' ||
            formData['device_type'] === 'SENSOR'
        "
        v-model="formData"
      />

      <form-equipment
        :errors="errors"
        v-if="
          formData['device_type'] === '2' ||
            formData['device_type'] === 'SPACE' ||
            formData.profile_type === 'space'
        "
        v-model="formData"
      />

      <div class="d-flex">
        <v-btn
          tile
          color="green"
          class="white--text"
          type="submit"
          elevation="0"
          >Сохранить</v-btn
        >
        <v-spacer></v-spacer>
      </div>
    </v-form>
  </div>
</template>

<script>
import FormTablet from "./ProfileFormTablet.vue";
import FormSensor from "./ProfileFormSensor.vue";
import FormEquipment from "./ProfileFormEquipment.vue";

export default {
  components: { FormTablet, FormSensor, FormEquipment },
  name: "ProfileForm",
  props: {
    formData: {
      type: Object,
      require: true,
    },
    listProfileType: {
      type: Array,
    },
  },
  data() {
    return {
      errors: {},
    };
  },
  methods: {
    validateFrom() {
      this.errors = {};

      const regName = /^([A-Za-zА-яЁё0-9 ]){3,127}$/;
      if (!regName.test(this.formData.name) || !this.formData.name) {
        this.errors.name = "Наименование должно быть от 3 до 127 символов";
      }

      if (this.formData["device_type"] === "2") {
        if (
          !this.formData.equipment_ids ||
          this.formData.equipment_ids.length <= 0
        ) {
          this.errors.equipment = "Добавьте оборудование";
        }
      }
      if (Object.keys(this.errors).length == 0) return true;
      else return false;
    },

    onSubmit() {
      if (!this.validateFrom()) return;

      let payload = { ...this.formData };
      delete payload["device_type"];
      if (
        this.formData["device_type"] === "2" ||
        this.formData.profile_type === "space"
      ) {
        if (!this.formData.id) {
          this.$store
            .dispatch("createSpaceProfile", payload)
            .then((response) => {
              this.$emit("onSubmit", response.data.data[0]);
            });
        } else {
          this.$store
            .dispatch("updateSpaceProfile", payload)
            .then((response) => {
              this.$emit("onSubmit", response.data.data[0]);
            })
            .catch((err) => {
              this.$emit("OnError", err);
            });
        }
      } else {
        if (!this.formData.id) {
          payload["device_type"] = this.listProfileType[
            this.formData["device_type"]
          ].type;
          this.$store.dispatch("createProfile", payload).then((response) => {
            this.$emit("onSubmit", response.data.data[0]);
          });
        } else {
          this.$store.dispatch("updateProfile", payload).then((response) => {
            this.$emit("onSubmit", response.data.data[0]);
          });
        }
      }
    },
  },
  computed: {},
};
</script>

<style></style>
